import React from 'react'
import { Admin, Resource } from 'react-admin'
import portugueseMessages from '@henriko/ra-language-portuguese'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { dataProvider, authProvider } from '../common/connection'
import { theme } from '../common/theme'
import { CategoryList, CategoryEdit, CategoryCreate, CategoryIcon } from '../resources/category'
import { ProductList, ProductEdit, ProductCreate, ProductIcon } from '../resources/product'
import { TagList, TagEdit, TagCreate, TagIcon } from '../resources/tag'
import { ColorList, ColorEdit, ColorCreate, ColorIcon } from '../resources/color'
import { SizeList, SizeEdit, SizeCreate, SizeIcon } from '../resources/size'

const messages = {
  pt: portugueseMessages
}

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'pt')

const App = () => {
  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
    >
      <Resource
        name='products'
        list={ProductList}
        create={ProductCreate}
        edit={ProductEdit}
        icon={ProductIcon}
        options={{ label: 'Produtos' }}
      />
      <Resource
        name='categories'
        list={CategoryList}
        create={CategoryCreate}
        edit={CategoryEdit}
        icon={CategoryIcon}
        options={{ label: 'Categorias' }}
      />
      <Resource
        name='sizes'
        list={SizeList}
        create={SizeCreate}
        edit={SizeEdit}
        icon={SizeIcon}
        options={{ label: 'Tamanhos' }}
      />
      <Resource
        name='colors'
        list={ColorList}
        create={ColorCreate}
        edit={ColorEdit}
        icon={ColorIcon}
        options={{ label: 'Cores' }}
      />
      <Resource
        name='tags'
        list={TagList}
        create={TagCreate}
        edit={TagEdit}
        icon={TagIcon}
        options={{ label: 'Tags' }}
      />
    </Admin>
  )
}

export default App
