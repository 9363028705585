import * as React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput
} from 'react-admin'
import BookIcon from '@material-ui/icons/Book'

export const CategoryIcon = BookIcon

export const CategoryList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField label='Chave' source='id' />
      <TextField label='Título' source='title' />
      <TextField label='Descrição' source='description' />
      <EditButton basePath='/categories' />
    </Datagrid>
  </List>
)

const CategoryTitle = ({ record }) => {
  return <span>Categoria {record ? `"${record.title}"` : ''}</span>
}

export const CategoryEdit = (props) => (
  <Edit title={<CategoryTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled label='Chave' source='id' />
      <TextInput label='Título' source='title' />
      <TextInput label='Descrição' source='description' multiline />
    </SimpleForm>
  </Edit>
)

export const CategoryCreate = (props) => (
  <Create title='Criar nova categoria' {...props}>
    <SimpleForm>
      <TextInput label='Chave' source='id' />
      <TextInput label='Título' source='title' />
      <TextInput label='Descrição' source='description' multiline />
    </SimpleForm>
  </Create>
)
