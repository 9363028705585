import * as React from 'react'
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput } from 'react-admin'
import FormatSizeIcon from '@material-ui/icons/FormatSize'

export const SizeIcon = FormatSizeIcon

export const SizeList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField label='Chave' source='id' />
      <TextField label='Nome' source='name' />
      <EditButton basePath='/sizes' />
    </Datagrid>
  </List>
)

const SizeTitle = ({ record }) => {
  return <span>Tamanho {record ? `"${record.title}"` : ''}</span>
}

export const SizeEdit = (props) => (
  <Edit title={<SizeTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled label='Chave' source='id' />
      <TextInput label='Nome' source='name' />
    </SimpleForm>
  </Edit>
)

export const SizeCreate = (props) => (
  <Create title='Criar nova cor' {...props}>
    <SimpleForm>
      <TextInput label='Chave' source='id' />
      <TextInput label='Nome' source='name' />
    </SimpleForm>
  </Create>
)
