import {
  FirebaseAuthProvider,
  FirebaseDataProvider
} from 'react-admin-firebase'

const config = {
  apiKey: 'AIzaSyBfGBlgk4uvInLh-HDs6QdJFvoKeeLIGw8',
  authDomain: 'annp-modas.firebaseapp.com',
  databaseURL: 'https://annp-modas.firebaseio.com',
  projectId: 'annp-modas',
  storageBucket: 'annp-modas.appspot.com',
  messagingSenderId: '902260725454',
  appId: '1:902260725454:web:053538596022083128229f',
  measurementId: 'G-8XDK712P8J'
}

// All options are optional
const options = {
  // Use a different root document to set your resource collections, by default it uses the root collections of firestore
  // rootRef: 'root-collection/some-doc',
  // Your own, previously initialized firebase app instance
  // app: firebaseAppInstance,
  // Enable logging of react-admin-firebase
  logging: true
  // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
  // watch: ['posts'],
  // Resources you explicitly dont want realtime updates for
  // dontwatch: ['comments'],
  // Authentication persistence, defaults to 'session', options are 'session' | 'local' | 'none'
  // persistence: 'session',
  // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
  // disableMeta: false,
  // Prevents document from getting the ID field added as a property
  // dontAddIdFieldToDoc: false,
  // Adds 'deleted' meta field for non-destructive deleting functionality
  // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true}
  // softDelete: false,
  // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
  // associateUsersById: false,
  // Casing for meta fields like 'createdby' and 'updatedby', defaults to 'lower', options are 'lower' | 'camel' | 'snake' | 'pascal' | 'kebab'
  // metaFieldCasing: 'lower'
}

export const dataProvider = FirebaseDataProvider(config, options)
export const authProvider = FirebaseAuthProvider(config, options)
