import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  ImageInput,
  ImageField,
  NumberInput,
  BooleanInput
} from 'react-admin'

const ProductTitle = ({ record }) => {
  return <span>Produto {record ? `"${record.title}"` : ''}</span>
}

export default (props) => (
  <Edit title={<ProductTitle />} {...props}>
    <SimpleForm>

      <BooleanInput
        label='Ativo'
        source='active'
      />

      <TextInput
        disabled
        label='Chave'
        source='id'
      />

      <TextInput
        label='Título'
        source='title'
      />

      <TextInput
        label='Descrição'
        source='description'
        multiline
      />

      <NumberInput
        label='Preço'
        source='price'
        min={0}
        max={999}
        step={0.10}
      />

      <SelectInput
        label='Gênero'
        source='gender'
        choices={[
          { id: 'M', name: 'Masculino' },
          { id: 'F', name: 'Feminino' },
          { id: 'U', name: 'Unisex' }
        ]}
      />

      <ReferenceArrayInput
        reference='categories'
        source='categories'
      >
        <SelectArrayInput
          label='Categorias'
          optionText='title'
        />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        reference='sizes'
        source='sizes'
      >
        <SelectArrayInput
          label='Tamanhos'
        >
          <ChipField
            source='name'
          />
        </SelectArrayInput>
      </ReferenceArrayInput>

      <ReferenceArrayInput
        reference='colors'
        source='colors'
      >
        <SelectArrayInput
          label='Cores'
        >
          <ChipField
            source='name'
          />
        </SelectArrayInput>
      </ReferenceArrayInput>

      <ReferenceArrayInput
        reference='tags'
        source='tags'
      >
        <SelectArrayInput
          label='Tags'
        >
          <ChipField
            source='name'
          />
        </SelectArrayInput>
      </ReferenceArrayInput>

      <ImageInput
        multiple
        source='catalog_image'
        label='Imagem do catálogo'
        accept='image/*'
      >
        <ImageField
          source='src'
          title='title'
        />
      </ImageInput>

    </SimpleForm>
  </Edit>
)
