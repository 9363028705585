import * as React from 'react'
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput } from 'react-admin'
import ColorLensIcon from '@material-ui/icons/ColorLens'

export const ColorIcon = ColorLensIcon

export const ColorList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField label='Chave' source='id' />
      <TextField label='Nome' source='name' />
      <TextField source='color' />
      <EditButton basePath='/colors' />
    </Datagrid>
  </List>
)

const ColorTitle = ({ record }) => {
  return <span>Cor {record ? `"${record.title}"` : ''}</span>
}

export const ColorEdit = (props) => (
  <Edit title={<ColorTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled label='Chave' source='id' />
      <TextInput label='Nome' source='name' />
      <TextInput label='Cor' source='color' />
    </SimpleForm>
  </Edit>
)

export const ColorCreate = (props) => (
  <Create title='Criar nova cor' {...props}>
    <SimpleForm>
      <TextInput label='Chave' source='id' />
      <TextInput label='Nome' source='name' />
      <TextInput label='Cor' source='color' />
    </SimpleForm>
  </Create>
)
